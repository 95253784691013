/* eslint-disable no-alert */
import { useDispatch } from 'react-redux';
import { useState, useRef } from 'react';
import MaxContainer from '@/components/atoms/MaxContainer';
import Layout from '@/components/Layout';
// import styles from './login.module.scss';
import isValidEmail from '@/services/formValidators/isValidEmail';
import { Form } from '@/components/organisms';
import { Button, Container, Input, Link, Text } from '@/components/atoms';
import auth from '@/api/auth';
import sentry from '@/services/sentry';
import { loadUserInfo } from '@/redux/checkout/checkoutReducer';
import useCheckoutRouter from '@/hooks/checkoutRouter';
import styles from './login.module.scss';
import useFormError from '@/hooks/useFormError';

const Login = () => {
  const dispatch = useDispatch();
  const checkout = useCheckoutRouter('login');
  const [errors, setErrors] = useState([]);
  const formRef = useRef(null);

  const onSubmit = async ({ email, password }) => {
    const response = await auth.login(email.toLowerCase(), password);
    sentry.setUser({ email });
    if (response.success) {
      response.data.persisted = true;
      dispatch(loadUserInfo(response.data));
      checkout();
    } else {
      setErrors(response.errors);
    }
  };
  useFormError(formRef, errors);

  return (
    <Layout type="onlyBrand">
      <Container
        margin="0px"
        style={{ width: '100%', padding: '2rem' }}
      >
        <MaxContainer max={600}>
          <Text
            textSize="xl"
            weight="semibold"
            center
            textStyle={{ marginBottom: '2rem' }}
          >
            Iniciar sesión
          </Text>
          <Text
            textColor="white"
            center
            textStyle={{ marginBottom: '4rem' }}
          >
            Ingresá tu email y contraseña para entrar a tu cuenta
          </Text>
          <Form
            getRef={(ref) => {
              formRef.current = ref.current;
            }}
            onSubmit={onSubmit}
          >
            <Input
              className="fullWidth"
              defaultValue=""
              id="Login Email Input"
              name="email"
              required
              label="Correo electrónico"
              type="email"
              validations={[isValidEmail]}
              autoFocus
              floatingLabel
            />

            <Input
              className="fullWidth"
              defaultValue=""
              id="Login Password Input"
              name="password"
              type="password"
              required
              label="Contraseña"
              validations={[
                (input) => {
                  if (input?.length > 5) return { isValid: true };
                  if (input?.length < 1)
                    return {
                      isValid: false,
                      errorMessage: 'Este campo es obligatorio'
                    };
                  return {
                    isValid: false,
                    errorMessage: 'Este campo debe tener 6 caracteres'
                  };
                }
              ]}
              floatingLabel
            />
            <div
              className="fullWidth"
              style={{ justifySelf: 'right' }}
            >
              <Link href="/forgot-password">
                <Text
                  variant="span"
                  weight="light"
                  colored
                >
                  Olvidé mi contraseña
                </Text>{' '}
              </Link>
            </div>
            <div className={`${styles.keypad} fullWidth`}>
              <Button
                style={{ flex: 1 }}
                id="Login Iniciar sesion"
                label="Iniciar Sesión"
                submit
                textWeight="semibold"
                variant="primary"
              />
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  gap: '.5rem',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Text
                  textSize="s"
                  textStyle={{ whiteSpace: 'nowrap' }}
                >
                  ¿No tienes una cuenta?
                </Text>
                <Link href="/signup">
                  <Text
                    weight="semibold"
                    textColor="white"
                    textSize="s"
                  >
                    Registrate
                  </Text>
                </Link>
              </div>
            </div>
          </Form>
        </MaxContainer>
      </Container>
    </Layout>
  );
};

export default Login;
